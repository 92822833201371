import React, { useContext, useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import images from '../../Constants/image'
import PdfCard from "./PdfCard";
import PdfCardForSumAndBom from "./PdfCardForSumAndBom";
import { get as getItems, getAllItemsWithBrandAndPrice, 
  getItemDefaultConfiguration, getAreaItemsDefaultConfiguration ,getAreaControllingItems} from '../../Services/Quote/Items';
import { get as getBrands} from '../../Services/Quote/Brands';
import { ToastContainer, toast } from 'react-toastify';
import { formatCurrency,comparator,styles, areaItemCodeOrder, getItemCodeOrder } from '../../Common/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faWindowRestore } from '@fortawesome/free-solid-svg-icons'
import BomTable from "./BomTable";
import Loader from '../Loader/loader';



const AddSpaces = () => {
  const {setDirection, currentComponent, setCurrentComponent, floors, 
    floorDetails, setFloorDetails, totalPrice, setTotalPrice,selectedItems, 
    setSelectedItems,selectedBrand, setSelectedBrand, selectedBrandName, 
    setSelectedBrandName,constructionStage, renovation, buildingType, 
    areaControllingItems, setAreaControllingItems } = useContext(DataContext);
  const [centralAC, setCentralAC] = useState(false);
  const [centralWaterHeating, setCentralWaterHeating] = useState(false);
 // const [selectedBrand, setSelectedBrand] = useState('elkr');
  const [brands, setBrands] = useState(null);
  const [allItemsWithBrandAndPrice, setAllItemsWithBrandAndPrice] = useState(null);
  const [isBrandDropdownDisabled, setIsBrandDropdownDisabled] = useState(false);
  const [itemDefaultConfiguration, setItemDefaultConfiguration] = useState(null);
  const [areaItemsDefaultConfiguration, setAreaItemsDefaultConfiguration] = useState(null);
  //const navigate = useNavigate ();

  const floorItemCodes = ["efit"];
  const areaItemCodes = ["dait"];
  const optionalItemCodes = ["opit"];
  const miscellaneousItemCodes = ["miit"];
  const installationCommissioningItemCodes = ["inco"];
  // Array list of floor codes
  const floorcodelist = ["fifl", "sefl", "thfl", "fofl", "fffl", "sifl"];
  const randomImageArea = ["batr", "bedr"];
  
  const [loader, setLoader] = useState(false);
  const [globalLoader, setGlobalLoader] = useState(true);
  const [floorItems, setFloorItems] = useState([]);
  const [areaItems, setAreaItems] = useState([]);
  const [optionalItems, setOptionalItems] = useState([]);
  const [listMiscellaneousItems, setListMiscellaneousItems] = useState([]);
  const [listInstallationCommissioningItems, setListInstallationCommissioningItems] = useState([]);
  const [listRestItems, setListRestItems] = useState([]);
  const [floorTrasnDetails, setFloorTrasnDetails] = useState([]);
  
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [defaultFloorTrasnDetails, setDefaultFloorTrasnDetails] = useState([]);
  //const [floorTrasnDetailsForSummary, setFloorTrasnDetailsForSummary] = useState([]);

  const [showSummary, setShowSummary] = useState(false);
  const [selectedArea, setSelectedArea] = useState(null);

  const [essentialItem, setEssentialItem] = useState([]);
  const [installationCommissioningItem, setInstallationCommissioningItem] = useState([]);


  

 // const areaItemCodeOrder = ['bedr', 'batr', 'drer', 'drar', 'lobb', 'dini', 'kitc', 'balc', 'pass', 'stai', 'elev'];


    async function data() {
      

        const brandsData = await getBrands();
        if (brandsData.status === 400) 
          { toast.error(brandsData.msg) } 
        else { 
          const filteredBrands =
                constructionStage === "alre"
                ? brandsData.data.filter(brand => brand.BrandCode === "eco")
                : brandsData.data.filter(brand => brand.BrandCode === "elkr" || brand.BrandCode === "wohn")
          setBrands(filteredBrands); 
          if(constructionStage === "alre")
          {
           setSelectedBrand('eco');
           setSelectedBrandName('Elkraft Eco');  
          }
                         
        }

        const itemDefaultConfigurationData = await getItemDefaultConfiguration(floors,buildingType);
        if (itemDefaultConfigurationData.status === 400) 
          { toast.error(itemDefaultConfigurationData.msg) } 
        else { 
         setItemDefaultConfiguration(itemDefaultConfigurationData.data);                   
        }
        
        const areaControllingItemsData = await getAreaControllingItems();
        if (areaControllingItemsData.status === 400) 
         { toast.error(areaControllingItemsData.msg) } 
        else { 
          setAreaControllingItems(areaControllingItemsData.data);                   
       }

       const areaItemsDefaultConfigurationData = await getAreaItemsDefaultConfiguration();
       if (areaItemsDefaultConfigurationData.status === 400) 
        { toast.error(areaItemsDefaultConfigurationData.msg) } 
       else { 
       setAreaItemsDefaultConfiguration(areaItemsDefaultConfigurationData.data);                   
      }

        const allItemsWithBrandAndPriceData = await getAllItemsWithBrandAndPrice();
        if (allItemsWithBrandAndPriceData.status === 400) 
          { toast.error(allItemsWithBrandAndPriceData.msg) } 
        else { 
          setAllItemsWithBrandAndPrice(allItemsWithBrandAndPriceData.data); 
          setSelectedItems([]);
          const floorItems = allItemsWithBrandAndPriceData.data.filter(item => floorItemCodes.includes(item.ItemTypeCode));
          setFloorItems(floorItems);

          let areaItems = allItemsWithBrandAndPriceData.data
          .filter(item => areaItemCodes.includes(item.ItemTypeCode))
          .filter(item => buildingType !== 'apar' || item.ItemCode !== 'elev');

          areaItems = areaItems.sort((a, b) => {
            return areaItemCodeOrder.indexOf(a.ItemCode) - areaItemCodeOrder.indexOf(b.ItemCode);
          });

          setAreaItems(areaItems);

          const optionalItems = allItemsWithBrandAndPriceData.data.filter(item => optionalItemCodes.includes(item.ItemTypeCode));
          setOptionalItems(optionalItems);

          const miscellaneousItems = allItemsWithBrandAndPriceData.data.filter(item => miscellaneousItemCodes.includes(item.ItemTypeCode));
          setListMiscellaneousItems(miscellaneousItems);

          const listRestItems = allItemsWithBrandAndPriceData.data.filter(item => !miscellaneousItemCodes.includes(item.ItemTypeCode));
          setListRestItems(listRestItems);

          const installationCommissioningItems = allItemsWithBrandAndPriceData.data.filter(item => installationCommissioningItemCodes.includes(item.ItemTypeCode));
          setListInstallationCommissioningItems(installationCommissioningItems);

        }   
        

        const details = transformData(allItemsWithBrandAndPriceData.data, itemDefaultConfigurationData.data);
        setFloorTrasnDetails(details);
        setDefaultFloorTrasnDetails(details);
        //setFloorTrasnDetailsForSummary(details);    
            

        setGlobalLoader(false);         
    }

    // to transform the items to set the view model for floor and area, its items
    const transformData = (allItems, defaultConfig) => {
      const floorDetails = [];

     // const uniqueFloorCodes = [...new Set(defaultConfig.map(config => config.FloorCode))];

     // uniqueFloorCodes.forEach((floorCode) => {
         let floorItem =allItems.find(item => item.ItemCode === floorcodelist[floors-1]);
         floorItem = {
          ...floorItem,
          ItemName: 'Edge Server & Gateways'
          //ItemName: floors+' Floor(s)'
        };
         setEssentialItem(floorItem);
         handleItemSelection(floorItem);

         let insandcommitem =allItems.find(item => item.ItemCode === "inco-"+floorcodelist[floors-1]);
         insandcommitem = {
                   ...insandcommitem,
                   ItemName: 'Installation & Commissioning'
                 }; 
     
         setInstallationCommissioningItem(insandcommitem);
         handleItemSelection(insandcommitem);
     // });
  
      defaultConfig.forEach((config) => {
          const {FloorCode, AreaCode, ItemCode, OrderByNum } = config;
          
          const areaItem = allItems.find(item => item.ItemCode === AreaCode);
          if (!areaItem) return;
  
          handleItemSelection(areaItem);
          const selectedItemsCodes = ItemCode.replace(/\"/g, '').split(',');
  
          const selectedItems = selectedItemsCodes.map(code => {
              return allItems.find(item => item.ItemCode === code.trim());
          }).filter(Boolean);
          handleItemSelection(selectedItems);
  
          const floorIndex = floorDetails.findIndex(floor => floor.ItemCode === FloorCode);

        // handleItemSelection(allItems.find(item => item.ItemCode === FloorCode));

        const uniqueRN = generateRandomNumber();

        let thefooritem=allItems.find(item => item.ItemCode === FloorCode);
        if (thefooritem) {
          thefooritem = {
            ...thefooritem,
            ApplyCentralAC: false,
            ApplyCentralWaterHeater: false
          };
        }
  
          if (floorIndex === -1) {
              floorDetails.push({
                  ...thefooritem,
                  SelectedAreas: [{
                      ...areaItem,
                      UniqueRN: uniqueRN,
                      SelectedItems: selectedItems,
                      ImageSrc: replaceIntegersWithRandomNumbers(areaItem.ImageSrc),
                      OrderByNum
                     // ImageSrc: randomImageArea.includes(areaItem.ItemCode) ? replaceIntegersWithRandomNumbers(areaItem.ImageSrc) : areaItem.ImageSrc
                  }]
              });
          } else {
              const floor = floorDetails[floorIndex];
              floor.SelectedAreas.push({
                  ...areaItem,
                  UniqueRN: uniqueRN,
                  SelectedItems: selectedItems,
                  ImageSrc: replaceIntegersWithRandomNumbers(areaItem.ImageSrc),
                  OrderByNum
                  //ImageSrc: randomImageArea.includes(areaItem.ItemCode) ? replaceIntegersWithRandomNumbers(areaItem.ImageSrc) : areaItem.ImageSrc
              });
          }
      });
  
      return floorDetails;
  };

  
const generateRandomNumber = () => {
   return Math.floor(Math.random() * 1000);
};

function replaceIntegersWithRandomNumbers(inputString) {
  // Define a function to generate a random number between 1 and 3
  const getRandomNumber = () => Math.floor(Math.random() * 10) + 1;

  // Use a regular expression to find all integers in the string and replace them with random numbers
  const replacedString = inputString.replace(/\d+/g, (match) => getRandomNumber());
  
  return replacedString;
}

    useEffect(() => {
     // setGlobalLoader(true);
        data()
        // if(constructionStage==="alre" && renovation===false)
        //  {
        //    setSelectedBrand('tata');
        //    setSelectedBrandName('Tata');
        //    setIsBrandDropdownDisabled(true);
        //  }  
       // setGlobalLoader(false);     
    }, [globalLoader]);  

    useEffect(() => {
       return SetBackground();         
  }, [listMiscellaneousItems]);  

  useEffect(() => {
   const cleanup = SetJavaScriptCode();
   return cleanup;        
}, [floorTrasnDetails]); 

//just to reload the items which are dependent on globalloader
useEffect(() => {
   //setGlobalLoader(true);
   //setGlobalLoader(false);      
}, [globalLoader]); 


const SetJavaScriptCode = () => {
   // Add event listeners for toggle buttons
   const toggleButtons = document.querySelectorAll('.toggle-btn');
   const addSpaceItems = document.querySelectorAll('.add-space');
   const addItemItems = document.querySelectorAll('.add-item');
   const viewdetails = document.querySelectorAll('.view-details');
   const spaceRowSidebar = document.querySelector('.space-row-sidebar');
   const closeBtn = document.querySelectorAll('.close-btn');

   const addRemoveView = document.querySelectorAll('.removeonmouseleave');

   

  const handleMouseOut = (event) => {
    toggleButtons.forEach(btn => {
      btn.classList.remove('active');
    });
  };

  addRemoveView.forEach(item => {    
    item.addEventListener('mouseleave', handleMouseOut);
  });   
 
   const handleToggleBtnClick = (event) => {    
    //  toggleButtons.forEach(btn => {
    //   btn.classList.remove('active');
    // });
     event.currentTarget.classList.toggle('active');
   };

   // Function to handle clicks outside of the toggle button
const handleClickOutside = (event) => {
  // Check if the click is outside of the '.toggle-btn' element
  if (!event.target.closest('.right-cstm-toggle')) {
    toggleButtons.forEach(btn => {
      btn.classList.remove('active');
    });
  }
};
 
   const handleAddSpaceClick = (event) => {
     event.currentTarget.classList.toggle('active');
   };
 
   const handleAddItemClick = () => {
     if (spaceRowSidebar) {
       spaceRowSidebar.classList.toggle('active');
     }
   };



   // Add event listener to the document to listen for clicks outside
document.addEventListener('click', handleClickOutside);
 
   toggleButtons.forEach(item => {
     item.addEventListener('click', handleToggleBtnClick);
     //item.addEventListener('mouseout', handleMouseOut);
   });
 
   addSpaceItems.forEach(item => {
     item.addEventListener('click', handleAddSpaceClick);
   });
     
 
   addItemItems.forEach(item => {
     item.addEventListener('click', handleAddItemClick);     
     //item.addEventListener('mouseout', handleMouseOut);
   });

   viewdetails.forEach(item => {
    item.addEventListener('click', handleAddItemClick);   
  });

   closeBtn.forEach(item => {
    item.addEventListener('click', handleAddItemClick);
   });
 
   // Cleanup function to remove event listeners
   return () => {

    addRemoveView.forEach(item => {    
      item.removeEventListener('mouseleave', handleMouseOut);
    });

     toggleButtons.forEach(item => {
       item.removeEventListener('click', handleToggleBtnClick);
     });
 
     addSpaceItems.forEach(item => {
       item.removeEventListener('click', handleAddSpaceClick);
     });
 
     addItemItems.forEach(item => {
       item.removeEventListener('click', handleAddItemClick);
     });

     viewdetails.forEach(item => {
      item.removeEventListener('click', handleAddItemClick);
    });

     
     closeBtn.forEach(item => {
      item.removeEventListener('click', handleAddItemClick);
     });

   };
 };
 
    

   function SetBackground() {
      const outsideDiv = document.getElementById('bgsection');
      const topImg = document.getElementById('topimg');
    
      // Update the class of the outside div
      if (outsideDiv) {
          outsideDiv.classList.add('screen3-bg');
      }

      if (topImg) {
         topImg.style.display = 'block';
     }

     const pHeader = document.getElementById('pheader');
    
      // Update the class of the outside div
      if (pHeader) {
        pHeader.innerHTML = "Configure Your Space And Add Items"; 
      }
    
      // Clean up when the component unmounts
      return () => {
        if (outsideDiv) {
          outsideDiv.classList.remove('screen3-bg');
        }

        if (topImg) {
         topImg.style.display = 'none';
       }
       if (pHeader) {
         pHeader.innerHTML = "Get The Quote In Few Easy Steps";
       }
      };
    }

 
  // read the data from the default configuration and then add it to the floor
  const addAreaToFloor = (floorItemCode, areaItemCode) => {
    // Find the floor with the given floorItemCode
    const foundFloor = defaultFloorTrasnDetails.find(
      (floor) => floor.ItemCode === floorItemCode
    );
  
    if (foundFloor) {
      // Find the area with the given areaItemCode within the found floor
      const foundArea = foundFloor.SelectedAreas.find(
        (area) => area.ItemCode === areaItemCode
      );
  
      if (foundArea) {
        handleItemSelection(foundArea);

      // Get the existing areas with the same ItemCode
      const existingAreasWithSameCode = foundFloor.SelectedAreas.filter(
        (area) => area.ItemCode === areaItemCode
      );

      // Find the highest OrderByNum among the existing areas
      const highestOrderByNum = existingAreasWithSameCode.reduce((max, area) => {
        return area.OrderByNum > max ? area.OrderByNum : max;
      }, 0);

      // Calculate the new OrderByNum by incrementing the highest value
      const newOrderByNum = highestOrderByNum + 1;
  
        // Update the OrderByNum of foundArea
        const updatedFoundArea = {
          ...foundArea,
          OrderByNum: newOrderByNum,
          UniqueRN: generateRandomNumber(),
          ImageSrc: replaceIntegersWithRandomNumbers(foundArea.ImageSrc)
        };
  
        // Add the found area to the floorTrasnDetails
        setFloorTrasnDetails((prevFloorTrasnDetails) => {
          const existingFloorIndex = prevFloorTrasnDetails.findIndex(
            (floor) => floor.ItemCode === foundFloor.ItemCode
          );
  
          if (existingFloorIndex !== -1) {
            // If the floor already exists, add the updated area to the existing floor
            const updatedFloors = [...prevFloorTrasnDetails];
            updatedFloors[existingFloorIndex].SelectedAreas.push(updatedFoundArea);
            return updatedFloors;
          } else {
            // If the floor doesn't exist, add the entire floor with the selected area
            return [
              ...prevFloorTrasnDetails,
              {
                ...foundFloor,
                SelectedAreas: [updatedFoundArea],
              },
            ];
          }
        });
      }
    }
  };
  


   
  const handleCheckboxChange = (item) => {
    setSelectedItems((prevSelectedItems) => {
        if (prevSelectedItems.some(selectedItem => selectedItem.ItemCode === item.ItemCode)) {
            return prevSelectedItems.filter((selectedItem) => selectedItem.ItemCode !== item.ItemCode);
        } else {
            return [...prevSelectedItems, item];
        }
    });
};

  const handleSelection = () => {
    setDirection('next');
    setCurrentComponent('contactform');
  };

  const handleItemSelection = (items) => {
   setSelectedItems((prevSelectedItems) => {
       const itemsArray = Array.isArray(items) ? items : [items];
       
       const updatedSelectedItems = itemsArray.reduce((acc, item) => {
           const itemExists = acc.some(selectedItem => selectedItem.ItemCode === item.ItemCode);
           
           if (itemExists) {
               return acc.map((selectedItem) => {
                   if (selectedItem.ItemCode === item.ItemCode) {
                    let quantity=1;
                    if(selectedArea)
                      {  
                        let checked=false;
                        if(item.ItemTypeCode==="dait") 
                          {
                            //area
                            checked = (selectedArea?.selectedAreaDetails.ItemCode === item.ItemCode && selectedArea?.selectedAreaDetails.UniqueRN === item.UniqueRN);
                          }
                          else if(item.ItemTypeCode==="opit") 
                          {
                              //item
                              checked=selectedArea?.selectedAreaDetails.SelectedItems.some(selectedItem => 
                                selectedItem.ItemCode === item.ItemCode && selectedItem.UniqueRN === item.UniqueRN);
                          }   

                       // checked = (selectedArea.ItemCode === item.ItemCode && selectedItem.UniqueRN === selectedArea.UniqueRN);
                      if(checked) // it already have and come here to remove only.
                      {
                          quantity=-1;
                      }
                     } 
                      let thisselectedItem={ ...selectedItem, Quantity: selectedItem.Quantity + quantity };
                       return thisselectedItem;;
                   }
                   return selectedItem;
               }).filter(item => item.Quantity > 0);;
           } else {
               return [...acc, { ...item, Quantity: 1 }];
           }          
       }, prevSelectedItems);

       itemsArray.forEach(item => updateSelectedAreaDetails(item));
       
       return updatedSelectedItems;
   });
    console.clear();
   //console.log('selectedItems');
  // console.log(selectedItems);
   
};

const updateSelectedAreaDetails = (item) => {

   if(!selectedArea)
   {
      return;
   }

   setSelectedArea((prevSelectedArea) => {
      const itemExists = prevSelectedArea?.selectedAreaDetails?.SelectedItems?.some(selectedItem => selectedItem.ItemCode === item.ItemCode);

       const updatedSelectedItems = !itemExists
           ? [...prevSelectedArea?.selectedAreaDetails.SelectedItems, item]
           : prevSelectedArea?.selectedAreaDetails.SelectedItems.filter(
                 selectedItem => selectedItem.ItemCode !== item.ItemCode
             );

      const updatedSelectedArea = {
           ...prevSelectedArea,
           selectedAreaDetails: {
               ...prevSelectedArea?.selectedAreaDetails,
               SelectedItems: updatedSelectedItems,
           },
       };

       if(item.ItemTypeCode==="opit")
        {
          updateFloorTrasnDetails(item, updatedSelectedArea.parentFloor.ItemCode, 
            updatedSelectedArea.selectedAreaDetails.ItemCode,
            updatedSelectedArea.selectedAreaDetails.UniqueRN);
        }
       return updatedSelectedArea;
   });
};

const updateFloorTrasnDetails = (item, floorcode, areacode, uniqueRN) => {
   setFloorTrasnDetails((prevfloorTrasnDetails) => {
       return prevfloorTrasnDetails.map(floor => {
           if (floor.ItemCode === floorcode) {
               const updatedSelectedAreas = floor.SelectedAreas.map(area => {
                   if (area.ItemCode === areacode && area.UniqueRN === uniqueRN) {
                       const itemExists = area.SelectedItems.some(selectedItem => selectedItem.ItemCode === item.ItemCode);
                       const updatedSelectedItems = itemExists
                           ? area.SelectedItems.filter(selectedItem => selectedItem.ItemCode !== item.ItemCode)
                           : [...area.SelectedItems, item];

                       return { ...area, SelectedItems: updatedSelectedItems };
                   }
                   return area;
               });
               return { ...floor, SelectedAreas: updatedSelectedAreas };
           }
           return floor;
       });
   });

};



  const calculateTotalPrice = () => {
    if (!selectedBrandName) return 0;
    const totalAmount = selectedItems.reduce((total, item) => {
        const price = item[selectedBrandName];
        return total + (price * item.Quantity);
    }, 0);
    setTotalPrice(totalAmount);
    return totalAmount;
};
    
  const handleBrandChange = (event) => {
    const selectedBrandCode = event.target.value;
    const selectedBrand = brands.find(brand => brand.BrandCode === selectedBrandCode);
    setSelectedBrand(selectedBrandCode);
    setSelectedBrandName(selectedBrand.Name);
    const updatedFloors = floorDetails.map((floor) => ({
      ...floor,
      brand: { key: selectedBrandCode, price: 10000 },
      rooms: floor.rooms.map((room) => ({
        ...room,
        items: room.items.map((item) => ({
          ...item,
          brand: { key: selectedBrandCode, price: item.brand.price },
        })),
      })),
    }));
    setFloorDetails(updatedFloors);
  };


  const handleIncreaseQuantity = (itemCode) => {
    const newItems = selectedItems.map(item => {
        if (item.ItemCode === itemCode) {
            return { ...item, Quantity: item.Quantity + 1 };
        }
        return item;
    });
    setSelectedItems(newItems);
};

const handleDecreaseQuantity = (itemCode) => {
  const newItems = selectedItems.map(item => {
      if (item.ItemCode === itemCode && item.Quantity > 0) {
          return { ...item, Quantity: item.Quantity - 1 };
      }
      return item;
  });
  setSelectedItems(newItems);
};
 


  const toggleActiveClass = (div) => {
   div.classList.toggle('active');
 };

 const handleAreaClick = (floor, area) => {
  console.log(area.ItemName);
  setSelectedArea({
       parentFloor: floor,
       selectedAreaDetails: area,
   });
};

const handleRemoveArea = async (floor, area) => {
  
if(!selectedArea?.selectedAreaDetails)
  {
    return;
  }

   // Show confirmation dialog
   if (window.confirm("Are you sure you want to remove " + selectedArea.selectedAreaDetails.ItemName+" this floor?")) {
   //if (window.confirm("Are you sure you want to remove " + area.ItemName + " this floor?")) {
    // Remove the area from floorTrasnDetails
    setFloorTrasnDetails((prevFloorTrasnDetails) => 
      prevFloorTrasnDetails.map((floor) => ({
        ...floor,
        SelectedAreas: floor.SelectedAreas.filter(
          (selectedArea) => selectedArea.UniqueRN !== area.UniqueRN
        )
      }))
    );



    // Remove items of the area from selectedItems
    handleItemSelection(area);
    handleItemSelection(area.SelectedItems);    

    setSelectedArea(null);
  }

};


const showCentralACAndHeaterQuestion = () => {
  return centralAC || centralWaterHeating
};



 const truncateText = (text) => {
   if (typeof text !== 'string') return '';
   return text.length > 20 ? `${text.substring(0, 20)}..` : text;
 };

//  const styles = {
//    pdfbuttons: {
//      display: 'flex',
//      justifyContent: 'center',
//      alignItems: 'center',
//      textAlign: 'center',
//    },
//    summaryitemhead: {
//      background:'darkgray'
//    },
//    summaryitemtotal:{
//     background:'#7d7a7a'
//   },
//    trLeftAlign: {
//     textAlign:'left'
//   },
//   trRightAlign: {
//     textAlign:'right'
//   },
//   trCenterAlign: {
//     textAlign:'center'
//   },
//  };

 const renderItemsWithHeaders = (selectedItems, selectedBrandName) => {
  let headers = {
    efit: false,
    dait: false,
    opit: false,
    miit: false,
  };

  return selectedItems.sort(comparator).map((item, index) => {
    let header = null;

    if (!headers[item.ItemTypeCode]) {
      switch (item.ItemTypeCode) {
        case 'efit':
          header = <tr key={'header-efit'} style={styles.summaryitemhead}><td colSpan="4">Essential Items</td></tr>;
          break;
        case 'dait':
          header = <tr key={'header-dait'} style={styles.summaryitemhead}><td colSpan="4">Area</td></tr>;
          break;
        case 'opit':
          header = <tr key={'header-opit'} style={styles.summaryitemhead}><td colSpan="4">Optional Products</td></tr>;
          break;
        case 'miit':
          header = <tr key={'header-miit'} style={styles.summaryitemhead}><td colSpan="4">Miscellaneous Items</td></tr>;
          break;
        case 'inco':
          header = <tr key={'header-miit'} style={styles.summaryitemhead}><td colSpan="4">Installation & Commissioning</td></tr>;
          break;
        default:
          break;
      }
      headers[item.ItemTypeCode] = true;
    }

    return (
      <React.Fragment key={index}>
        {header}
        <tr>
          <td>{item.ItemName}</td>          
          <td style={styles.trCenterAlign}>{item.Quantity}</td>
          <td style={styles.trRightAlign} >{formatCurrency(item[selectedBrandName])}</td>
          <td style={styles.trRightAlign}>{formatCurrency(item[selectedBrandName] * item.Quantity)}</td>
        </tr>
      </React.Fragment>
    );
  });
};



const renderItemsWithHeadersForSummaryWithTable = (floorTrasnDetails, selectedBrandName) => {

 
 const essentialItemRow =<><tr key={'header-efit'} style={styles.summaryitemhead}><td colSpan="2">Essential Items</td></tr>
  <tr key={'floor-efit'} >           
    <td style={styles.areaItem} >{essentialItem.ItemName}</td>
    <td style={styles.trRightAlign}>{formatCurrency(essentialItem[selectedBrandName])}</td>
  </tr></>;

const selectedMiscellaneousItems = filteredMiscellaneousItems.filter(miscItem => 
  selectedItems.some(selectedItem => selectedItem.ItemCode === miscItem.ItemCode)
);

const insandcommItemRow =<><tr key={installationCommissioningItem.ItemCode} style={styles.summaryitemhead}><td >Installation & Commissioning</td>
<td style={styles.trRightAlign}>{formatCurrency(installationCommissioningItem[selectedBrandName])}</td>
</tr></>;


  // Function to get the order based on the areaItemCodeOrder
  // const getItemCodeOrder = (itemCode) => {
  //   const index = areaItemCodeOrder.indexOf(itemCode);
  //   return index !== -1 ? index : areaItemCodeOrder.length; // Items not in the array are placed at the end
  // };

  return (
    <table style={styles.table}>
      <thead>
        <tr style={styles.summaryitemtotal}>
          <th>Item Name</th>
          <th style={styles.trRightAlign}>Price ({selectedBrandName})</th>
        </tr>
      </thead>
      <tbody>
        {essentialItemRow}
        {floorTrasnDetails.sort((a, b) => a.Id - b.Id).map((floor, floorIndex) => {


          return (
            <React.Fragment key={floorIndex}>
              
              {/* Render the floor (essential item) */}
              <tr key={`floor-${floor.Id}`} style={styles.summaryitemhead}>
                <td colSpan="2"><strong>{floor.ItemName}</strong></td>
              </tr>

              {/* Sort SelectedAreas based on areaItemCodeOrder and OrderByNum */}
              {floor.SelectedAreas
                .sort((a, b) => {
                  const codeOrderA = getItemCodeOrder(a.ItemCode);
                  const codeOrderB = getItemCodeOrder(b.ItemCode);
                  if (codeOrderA === codeOrderB) {
                    return a.OrderByNum - b.OrderByNum;
                  }
                  return codeOrderA - codeOrderB;
                })
                .map((area, areaIndex) => {                
                  // Calculate total price for the area
                const areaPrice = parseFloat(area[selectedBrandName]) || 0; // Ensure it's a number
                const selectedItemsPrice = area.SelectedItems.reduce(
                  (total, item) => total + (parseFloat(item[selectedBrandName]) || 0) * (item.Quantity || 1),
                  0
                );
                const totalAreaPrice = areaPrice + selectedItemsPrice;

                // Build a string of item names and prices
                const selectedItemsNamesAndPrices = area.SelectedItems.length > 0
                  ? area.SelectedItems.map(item => {
                      const itemPrice = parseFloat(item[selectedBrandName]) || 0;
                      return `${item.ItemName} (${formatCurrency(itemPrice)})`;
                    }).join(', ')
                  : '';

                                
                // Generate the areaTitle with area price and individual item prices
                const areaTitle = selectedItemsNamesAndPrices
                ? `${area.ItemName} (${formatCurrency(areaPrice)}) - ${selectedItemsNamesAndPrices}`
                : `${area.ItemName} (${formatCurrency(areaPrice)})`;

                  return (
                    <React.Fragment key={`${floorIndex}-${areaIndex}`}>
                      {/* Render Selected Areas */}
                      <tr key={`area-${area.Id}`} title={areaTitle}>
                        <td style={styles.areaItem} >{area.ItemName} - {area.OrderByNum}</td>
                        <td style={styles.trRightAlign}>{formatCurrency(totalAreaPrice)}</td>
                      </tr>
                    </React.Fragment>
                  );
                })}
            </React.Fragment>
          );
        })}
        
        {selectedMiscellaneousItems && selectedMiscellaneousItems.length >0 ?(
          <>
          <tr key={'header-miit'} style={styles.summaryitemhead}><td colSpan="2">Miscellaneous Items</td></tr>
          {selectedMiscellaneousItems.map((item, index) => (          
           <tr key={`area-${item.Id}`} >
           <td style={styles.areaItem} >{item.ItemName}</td>
           <td style={styles.trRightAlign}>{formatCurrency(item[selectedBrandName])}</td>
         </tr>
        ))}
        </>
        ):(<></>)}
        {insandcommItemRow}
        <tr style={styles.summaryitemtotal}>
              <th style={styles.trLeftAlign}>Total</th>
              <th colSpan="3" style={styles.trRightAlign} >
              {formatCurrency(calculateTotalPrice())}
              </th>
            </tr>
      </tbody>
    </table>
  );
};




const centralACORWaterHeaterQuestion = (floorCode, property, value) => {
  setFloorTrasnDetails(prevDetails =>
    prevDetails.map(floor =>
      floor.ItemCode === floorCode ? { ...floor, [property]: value } : floor
    )
  );

   if(property==="ApplyCentralAC")
    {
      const caccItem = listMiscellaneousItems.find(item => item.ItemCode === 'cacc');
    
    // Check if any floor has ApplyCentralAC set to true
    const anyFloorHasAC = floorTrasnDetails.some(floor => floor.ApplyCentralAC); // it used to have old value
    
    // Check if selectedItems contains an item with ItemCode 'cacc'
    const caccInSelectedItems = selectedItems.some(item => item.ItemCode === 'cacc');
    
    // If any floor has ApplyCentralAC true and caccItem is not in selectedItems
    // if (anyFloorHasAC && !caccInSelectedItems) 
    // {
    //   handleCheckboxChange(caccItem);
    // }
    
    // If every floor has ApplyCentralAC false and caccItem is in selectedItems
    const allFloorsHaveNoAC = floorTrasnDetails.every(floor => !floor.ApplyCentralAC); // it used to have old value
    
    //if (allFloorsHaveNoAC && caccInSelectedItems) {
      handleCheckboxChange(caccItem);
   // }      
    }  

  // Get the spaceRowSidebar element
  const spaceRowSidebar = document.querySelector('.space-row-sidebar');
  removeActiveClass(spaceRowSidebar);

};


const filteredOptionalItems = optionalItems.filter(item => {
  // Check if the item exists in areaItemsDefaultConfiguration based on the selectedArea's AreaCode
  const isInDefaultConfig = areaItemsDefaultConfiguration.some(config => 
    config.AreaCode === selectedArea?.selectedAreaDetails.ItemCode && config.ItemCode === item.ItemCode
  );

  // Apply filtering based on Central AC and Central Water Heater conditions
  if (selectedArea?.parentFloor.ApplyCentralAC && item.ItemCode === 'acvr') {
    return false; // Exclude AC controller for individual if Central AC is applied
  }

  if (selectedArea?.parentFloor.ApplyCentralWaterHeater && item.ItemCode === 'geys') {
    return false; // Exclude Individual Gyser if Central Water Heater is applied
  }

  return isInDefaultConfig;
});

const removeActiveClass = (element) => {
  if (element) {
    element.classList.remove('active');
  }
};


  const filteredMiscellaneousItems = listMiscellaneousItems
  .filter(item => {
    if (buildingType === "apar" && ["wpac", "wlse", "mgac", "geac"].includes(item.ItemCode)) {
      return false; // Exclude these items if buildingType is "apar"
    }
    if (item.ItemCode === "cacc") {
      return false; // Exclude "cacc" 
    }
    return true; // Include all other items
  });

  const filteredMiscellaneousItemsRow1=filteredMiscellaneousItems.filter(item => {
    if(["ctsc", "mgac", "vdbe"].includes(item.ItemCode))
    {
      return true;
    }
  });

  const filteredMiscellaneousItemsRow2=filteredMiscellaneousItems.filter(item => {
    if(!["ctsc", "mgac", "vdbe"].includes(item.ItemCode))
    {
      return true;
    }
  });

  return (
    <>
    <div >
    <Loader toggle={globalLoader} />


<div class="screen3-box">
               <div class="screen3-box-row">
                  <div class="col-box1">
                    <div class="container">
                     <div class="row-cstm header-check-option">
                        <div class="check-box">
                           <label for="ac-option1"><img alt="AC" src={`${images.offac}`}/> AC</label>
                           <div>
                              <input type="checkbox" checked={centralAC} id="ac-option1" name="ac-option1" onClick={() => setCentralAC(true)}/>
                              <label for="ac-option1">Centralized</label>
                           </div>
                           <div>
                              <input type="checkbox" checked={!centralAC} id="ac-option2" name="ac-option2" onClick={() => setCentralAC(false)}/>
                              <label for="ac-option2">Independent</label>
                           </div>
                        </div>
                        <div class="check-box">
                           <label for="wh-option1"><img alt="Water Heater" src={`${images.offheater}`} onClick={() => setCentralWaterHeating(true)}/> Water Heater</label>
                           <div>
                              <input type="checkbox" checked={centralWaterHeating} id="wh-option1" name="wh-option1" onClick={() => setCentralWaterHeating(true)}/>
                              <label for="wh-option1">Centralized</label>
                           </div>
                           <div>
                              <input type="checkbox" checked={!centralWaterHeating} id="wh-option2" name="wh-option2" onClick={() => setCentralWaterHeating(false)}/>
                              <label for="wh-option2">Independent</label>
                           </div>
                        </div>
                     </div>
                     </div>
                     
            {floorTrasnDetails.map((floor) => {
            //  Group SelectedAreas by ItemCode
  const groupedAreas = floor.SelectedAreas.reduce((groups, area) => {
    let groupKey;
    if (['bedr'].includes(area.ItemCode)) groupKey = 'first-group';
    else if (['batr'].includes(area.ItemCode)) groupKey = 'second-group';
    else if (['drer'].includes(area.ItemCode)) groupKey = 'third-group';
    else if (['drar', 'lobb', 'dini', 'kitc'].includes(area.ItemCode)) groupKey = 'fourth-group';
    else if (['pass', 'stai', 'balc', 'elev'].includes(area.ItemCode)) groupKey = 'fifth-group';

    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(area);
    return groups;
  }, {});

  const groupOrder = ['first-group', 'second-group', 'third-group', 'fourth-group', 'fifth-group'];

            return (
              <div key={floor.Id} class="main-box-floor">
                <div class="container">
                <div class="row-cstm-plan1">
                  <div onClick={() => setSelectedFloor(floor)} class="right-cstm-toggle">
                    <div class="toggle-btn"><span></span><span></span><span></span></div>
                    <div class="click-how-dada ">
                      <ul class="list"><li class="add-space">Add Space
                     <ul class="sub-menu ">
                        <ul>
                        {areaItems.map(item => (
                                       <li onClick={() => addAreaToFloor(floor.ItemCode,item.ItemCode)}><a>{item.ItemName}</a></li>
                                    ))}
                        </ul>
                        </ul>
                        </li>
                        {/* <li class="delete-floor">Delete Floor</li> */}
                        </ul>
                        </div></div>
                    <h4>{floor.ItemName}</h4>                   

                    {(centralAC || centralWaterHeating) && (        
      
                        <div class="row-plan">
                          {(centralAC) && (      
                           <div class="check-box">
                              <div class="group">
                                 <h4 onClick={() => centralACORWaterHeaterQuestion(floor.ItemCode, 'ApplyCentralAC', true )}>Do you want to automate the centralized AC on this floor?</h4>
                                 <div className={`house ${floor.ApplyCentralAC === true ? 'active' : ''}`}
                                 onClick={() => centralACORWaterHeaterQuestion(floor.ItemCode, 'ApplyCentralAC', true )}>
                                    {/* <input type="radio" id="ac-yes" name="ApplyCentralAC" value={true}
                                    checked={floor.ApplyCentralAC === true}/> */}
                                    <div class="animate-image">
                                       <img class="frame-6 normal-img" src={`${images.ca}`} alt="House Icon"/>
                                       <img class="frame-6 hover-img" src={`${images.cap}`} alt="House Icon"/>
                                    </div>
                                    <label for="ac-yes" class="text-wrapper-5">Yes</label>
                                 </div>
                                 <div className={`house ${floor.ApplyCentralAC === false ? 'active' : ''}`}
                                 onClick={() => centralACORWaterHeaterQuestion(floor.ItemCode, 'ApplyCentralAC', false )}>
                                    {/* <input type="radio" id="ac-no" name="ApplyCentralAC" value={false}
                                    checked={floor.ApplyCentralAC === false}/> */}
                                    <div class="animate-image">
                                       <img class="frame-6 normal-img" src={`${images.ca}`} alt="House Icon"/>
                                       <img class="frame-6 hover-img" src={`${images.cap}`} alt="House Icon"/>
                                    </div>
                                    <label for="ac-no" class="text-wrapper-5">No</label>
                                 </div>
                              </div>
                           </div>
                           )}
                            {(centralWaterHeating) && (  
                           <div class="check-box">
                              <div class="group">
                                 <h4>Do you want to use the centralized Water Heater on this floor?</h4>
                                 <div className={`house ${floor.ApplyCentralWaterHeater === true ? 'active' : ''}`}                                 
                                 onClick={() => centralACORWaterHeaterQuestion(floor.ItemCode, 'ApplyCentralWaterHeater', true )}>
                                    <input type="radio" id="wh-yes" name="ApplyCentralWaterHeater" value={true}
                                    checked={floor.ApplyCentralWaterHeater === true}/>
                                    <div class="animate-image">
                                       <img class="frame-6 normal-img" src={`${images.ch}`} alt="House Icon"/>
                                       <img class="frame-6 hover-img" src={`${images.chp}`} alt="House Icon"/>
                                    </div>
                                    <label for="wh-yes" class="text-wrapper-5">Yes</label>
                                 </div>
                                 <div className={`house ${floor.ApplyCentralWaterHeater === false ? 'active' : ''}`}
                                 onClick={() => centralACORWaterHeaterQuestion(floor.ItemCode, 'ApplyCentralWaterHeater', false )}>
                                    <input type="radio" id="wh-no" name="ApplyCentralWaterHeater" value={false}
                                    checked={floor.ApplyCentralWaterHeater === true}/>
                                    <div class="animate-image">
                                       <img class="frame-6 normal-img" src={`${images.ch}`} alt="House Icon"/>
                                       <img class="frame-6 hover-img" src={`${images.chp}`} alt="House Icon"/>
                                    </div>
                                    <label for="wh-no" class="text-wrapper-5">No</label>
                                 </div>
                              </div>
                           </div>
                           )}
                        </div>
                    )}
                        <div class="row-plan-img-box">
{floor.SelectedAreas?(<>
                                          


{groupOrder.map(group => (
      <div key={group} className={`img-cstm-box ${group}`}>
        {groupedAreas[group]?.map(area => (
      <div key={area.UniqueRN} className={`col-box-loop ${group}`} onClick={() => handleAreaClick(floor, area)}>
        <div className="img">
          <img src={`/img/${area.ImageSrc}`} alt={area.ItemName} />
        </div>
        <div className="data-img">
          <h5 title={area.ItemName}>{area.ItemName} -{area.OrderByNum}
        
          </h5>
          <div className="right-cstm-toggle">
            {area.ItemCode !== 'elev' && (
              <button className='add-item' title='Optional Items' onClick={() => handleAreaClick(floor, area)}>
                <FontAwesomeIcon icon={faEdit} size="1x" />
              </button>
            )}
            <button className='mr-3' title='Remove Space'>
              <FontAwesomeIcon icon={faTrash} size="1x" onMouseEnter={() => handleAreaClick(floor, area)} onClick={() => handleRemoveArea(floor,area)} />
            </button>
          </div>
          <div className="right-icon-img">
            <ul>
              <li key="lighting" title="Lighting">
                <img src={`${images.offbulb}`} alt="Lighting" />
              </li>
              <li key="touchpanel" title="Touch Panel">
                <img src={`${images.offtv}`} alt="Touch Panel" />
              </li>
              {area.SelectedItems.map(item => (
                <li key={item.Id} title={item.ItemName}>
                  <img src={`/img/${item.ImageSrc}`} alt={item.ItemName} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
   ))}
  </div>
))}

                                          </>):(<p>Loading...</p>)}

                                         
                                          </div>
                   
                                          </div>
                </div>
              </div>
            )
            })}
                    

                    
                  </div>
                  <div class="col-box2">
                     <div id="divAddItemChk" class="space-row-sidebar">
                      <div class="check-box">
                          <h4>Optional Items
                          <span class="close-btn">✖</span>
                          </h4>
                          
                          {selectedArea?(< >
                                            <h4>{selectedArea?.parentFloor.ItemName}</h4>
                                            <h4 title={selectedArea?.selectedAreaDetails.ItemName}>{selectedArea?.selectedAreaDetails.ItemName}</h4>
                                       </>):(<p>Loading...</p>)}

                    {optionalItems ? (
      <>
        {filteredOptionalItems.map((item) => (
          <div title={item.ItemName} key={item.UniqueRN}>
            <img src={`/img/${item.ImageSrc}`} alt="bulb" />
            <input
              type="checkbox"
              id={item.ItemCode}
              name={item.ItemCode}
              checked={selectedArea?.selectedAreaDetails.SelectedItems.some(
                selectedItem => selectedItem.ItemCode === item.ItemCode
              )}
              onChange={() => handleItemSelection(item)}
            />
            <label htmlFor={item.ItemCode}>{item.ItemName}</label>
          </div>
        ))}
      </>
    ) : (
      <p>Loading...</p>
    )}


                          
                      </div>
                  </div>

                
                  </div>
               </div>
            </div>
    </div>

    <div class="screen3-box-bottombar">
     
        <div class="miscellaneous-item">
        <div class="container">
                          <div class="check-box">
                             <label>Miscellaneous Items</label>
                             
<div class="miscel-item box-inner1">
{filteredMiscellaneousItemsRow1.map((item) => (
            <div key={item.Id}>
              <img src={`/img/${item.ImageSrc}`} alt="i7"/>
                <input
                  type="checkbox" id={item.ItemCode} name={item.ItemCode}
                  checked={selectedItems.some(selectedItem => selectedItem.ItemCode === item.ItemCode)}
                  onChange={() => handleCheckboxChange(item)}
                />
                <label for={item.ItemCode}>             
                {item.ItemName}
                </label>           
            </div>
          ))}
 </div>        

 <div class="miscel-item box-inner2">
{filteredMiscellaneousItemsRow2.map((item) => (
            <div key={item.Id}>
              <img src={`/img/${item.ImageSrc}`} alt="i7"/>
                <input
                  type="checkbox" id={item.ItemCode} name={item.ItemCode}
                  checked={selectedItems.some(selectedItem => selectedItem.ItemCode === item.ItemCode)}
                  onChange={() => handleCheckboxChange(item)}
                />
                <label for={item.ItemCode}>             
                {item.ItemName}
                </label>           
            </div>
          ))}
</div>

         </div>
        </div>                       
      </div>
      
    
                     <div class="row-plan-price">
                      <div class="form-container next-box">
                              <div class="form-group">
                                  <label for="brand">Brand</label>
                                  <label>
       
                                 {brands ? (
                                 <select disabled={isBrandDropdownDisabled} value={selectedBrand} onChange={handleBrandChange}>
                                    {brands.map((item) => (
                                       <option key={item.BrandCode} value={item.BrandCode}>
                                       {item.Name}
                                       </option>
                                    ))}
                                 </select>
                                 ) : (
                                 <p>Loading...</p>
                                 )}
                                 </label>
                              </div>
                          <div class="price-box">
                              <span><strong>Total Price (INR):</strong>  {formatCurrency(calculateTotalPrice())} </span>
                          </div>
                          <div class="btn-cstm" onClick={() => { setShowSummary(!showSummary)}}>
                              <a href="javascript:void(0)" class="btn"><img alt="View Details" src={`${images.viewsummery}`}/>View Details</a>
                          </div>
                      </div>
                      <div>
   {totalPrice > 0 && (
        <div class="summry-box" style={{ display: showSummary ? 'block' : 'none' }}>
        <h2>Details</h2>
        <table >
          <thead>
            <tr>
              <th>Item</th>
              <th style={styles.trCenterAlign}>Quantity</th>
              <th style={styles.trRightAlign}>Unit Price</th>
              <th style={styles.trRightAlign}>Amount (INR)</th>
            </tr>
          </thead>
          <tbody>

          {renderItemsWithHeaders(selectedItems, selectedBrandName)}
            
            <tr style={styles.summaryitemhead}>
            {/* <th colSpan="2"></th> */}
              <th style={styles.trLeftAlign}>Total</th>
              <th colSpan="3" style={styles.trRightAlign} >
              {formatCurrency(calculateTotalPrice())}
              </th>
            </tr>
          </tbody>
        </table>   
        {/* <div style={styles.pdfbuttons}>
        {selectedItems && selectedItems.length > 0 && (
        <PdfCard title="Automateo Generate Quote" selecteditems={selectedItems} selectedbrand={selectedBrand} selectedbrandname={selectedBrandName} totalprice={totalPrice} />
        )}
        </div> */}
      </div>
      )}
      <div class="summry-box" style={{ display: true ? 'block' : 'none' }}>
      <h2>Summary</h2>
      {renderItemsWithHeadersForSummaryWithTable(floorTrasnDetails, selectedBrandName)}
      </div>
      <div class="summry-box" style={{ display: true ? 'block' : 'none' }}>
      {selectedItems && selectedItems.length > 0 && (
        <BomTable title="Automateo Generate Quote" areaControllingItems={areaControllingItems} selectedItems={selectedItems} selectedbrand={selectedBrand} essentialItem={essentialItem} />
        )}
        <div id='pdfbuttons' style={styles.pdfbuttons}>
        {selectedItems && selectedItems.length > 0 && (
        <PdfCardForSumAndBom  title="Automateo Generate Quote" 
        selecteditems={selectedItems} 
        selectedbrand={selectedBrand} 
        selectedbrandname={selectedBrandName} 
        totalprice={totalPrice} 
        areaControllingItems={areaControllingItems}
        essentialItem={essentialItem}
        installationCommissioningItem={installationCommissioningItem}
        floorTrasnDetails={floorTrasnDetails}
        />
        )}
        </div>
      </div>
</div>
                  </div>
    </div>

    <div class="pagination">
              <div class="item-box cstm-item" onClick={() => { setDirection('back'); setCurrentComponent('buildingtype')}}>1</div>
              <div class="item-box cstm-item" onClick={() => { setDirection('back'); setCurrentComponent('constructionstage')}}>2</div>
              <div class="item-box active" >3</div>
              <div onClick={() => {handleSelection()}} class="item-box next">Next <img class="img" src={`${images.next}`} alt="Next Icon" /></div>
      </div>
    </>
  );
};

export default AddSpaces;
