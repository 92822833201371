// src/components/ContactForm.js
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import DataContext from '../../context/DataContext';
import { sendemail } from '../../Services/Quote/SendEmail';
import { ToastContainer, toast } from 'react-toastify';


const ContactForm = () => {
  const { setUserDetails, blobpdf, setBlobpdf  } = useContext(DataContext);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const [isHighlighted, setIsHighlighted] = useState(true);

  const [formData, setFormData] = useState({
    to: '',
    attachment: null,
    firstName:'',
    lastName:'',
    company:'',
    city:'',
    email:'',
    mobile:''
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  useEffect(() => {

    // Target the div outside the root element
    return SetBackground();
  }, [isHighlighted]);

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA');
      return;
    }
    // alert("wow...");

    const data = new FormData();
    data.append('to', formData.to);
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('company', formData.company);
    data.append('city', formData.city);
    data.append('mobile', formData.mobile);

     // If blob exists, append it to the form data
    if (blobpdf) {
      data.append('attachment', blobpdf, 'quote.pdf');
    }

     const emailstatus = await sendemail(data);
     console.log(emailstatus)
        if (emailstatus.status === 400) 
          { 
            alert(emailstatus.msg);     
            toast.error(emailstatus.msg) 
          } 
        else { 
          //alert(emailstatus.data);
          window.location.href = "https://calc.automateo.de/ThankYou";                          
        }
  };

  function SetBackground() {
    const outsideDiv = document.getElementById('bgsection');
  
    // Update the class of the outside div
    if (outsideDiv) {
        outsideDiv.classList.add('screen4-bg');
    }
  
    // Clean up when the component unmounts
    return () => {
      if (outsideDiv) {
        outsideDiv.classList.remove('screen4-bg');
      }
    };
  }

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
    <div class="main-container">
      <div class="text-wrapper-4">Contact Us</div>
      <div class="contact-form">
      <form onSubmit={handleSubmit}>
        <div class="form-group">
        <label for="first-name">First Name</label>
        <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleInputChange} required />
        </div>
        <div class="form-group">
                            <label for="last-name">Last Name</label>
        <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleInputChange} required />
        </div>
        <div class="form-group">
                            <label for="Company">Company</label>
        <input type="text" name="company" placeholder="Company (Optional)" value={formData.company} onChange={handleInputChange} />
        </div>
        <div class="form-group">
                            <label for="City">City</label>
        <input type="text" name="city" placeholder="City (Optional)" value={formData.city} onChange={handleInputChange} />
        </div>

        <div class="form-group">
        <label for="Email">Email</label>
        <input type="email" name="to" placeholder="Email" value={formData.to} onChange={handleInputChange} required />
        </div>
        <div class="form-group">
        <label for="Mobile">Mobile</label>
        <input type="tel" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleInputChange} required />
        </div>
        {/* Add captcha */}
        <div>
        <ReCAPTCHA
          sitekey="6LenTVkqAAAAANZQ5cYEqkUy8j2n8676QimxQsnE"
          onChange={handleRecaptchaChange}
        />
         {/* <input type="file" onChange={handleFileChange} /> */}
      </div>
        <button type="submit">Send</button>
        

      
      </form>
      </div>
    </div>
    </>
  );
};

export default ContactForm;
